<template>
  <div>
    <IndexSP />
    <div class="d-flex body">
      <Index class="d-none d-md-block" />
      <div style="padding-right: 20px; max-width:900px">
        <div class="d-none d-md-block" style="height: 98px" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import IndexSP from "@/components/IndexSP.vue";
import Index from "@/components/Index.vue";

export default {
  components: {
    IndexSP,
    Index,
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.body {
  padding: 0px 16px;
}
.video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.video iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
