<template>
  <div class="home row">
  <div class="col-6 mb-0">
    <router-link to="/work/happyvirus">
      <img class="card-img-top"  style="width: 100%;" src="@/assets/sq_virus.jpg">
      <div style="text-align: center;" class="mb-3">Happy Virus</div>
    </router-link>
  </div>
  <div class="col-6 mb-0">
    <router-link to="/work/executive">
      <img class="card-img-top"  style="width: 100%;" src="@/assets/sq_ai.png">
      <div style="text-align: center;" class="mb-3">Executive Reproduction</div>
    </router-link>
  </div>
  <div class="col-6 mb-0">
    <router-link to="/work/dystopia">
      <img class="card-img-top"  style="width: 100%;" src="@/assets/sq_dystopia.png">
        <div style="text-align: center;" class="mb-3">Dystopia-Utopia</div>
    </router-link>
  </div>
  <div class="col-6 mb-0">
    <router-link to="/work/broken">
      <img class="card-img-top"  style="width: 100%;" src="@/assets/sq_broken.png">
      <div style="text-align: center;" class="mb-3">Broken Heart</div>
    </router-link>
  </div>
  <div class="col-6 mb-0">
    <router-link to="/work/ofuro">
      <img class="card-img-top"  style="width: 100%;" src="@/assets/sq_ofuro.png">
      <div style="text-align: center;" class="mb-3">入学式 in OFURO</div>
    </router-link>
  </div>
  </div>
</template>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";
// import Index from "@/components/Index.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
    // Index
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.home div {
  margin: 1rem 0 4rem 0;
  color: #111;
}
</style>
