<template>
  <div>
    <div>
      <nav class="navbar navbar-light d-block d-md-none">
        <div class="d-flex justify-content-between">
          <div class="mt-3">
            <router-link class="h4" to="/">EmiIwado</router-link>
            <div class="small mt-1" style="color: gray">
              designer, artist, entrepreneur
            </div>
          </div>
          <div
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
          >
            <span class="navbar-toggler-icon mt-3" style="height: 20px"></span>
          </div>
        </div>
        <div class="collapse navbar-collapse mt-2" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <div
                class="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                style="cursor: pointer"
              >
                WORK
              </div>
              <div class="dropdown-menu">
                <div>
                  <router-link to="/work/happyvirus">Happy Virus</router-link>
                </div>
                <div>
                  <router-link to="/work/executive"
                    >Executive Reproduction</router-link
                  >
                </div>
                <div>
                  <router-link to="/work/broken">Broken Heart</router-link>
                </div>
                <div>
                  <router-link to="/work/ofuro">入学式 in OFURO</router-link>
                </div>
                <div>
                  <router-link to="/work/dystopia">Dystopia-Utopia</router-link>
                </div>
                <div>
                  <router-link to="/work/todai">Todai 女子のうた</router-link>
                </div>
              </div>
            </li>
            <li>
              <router-link class="nav-link" to="/about">ABOUT</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/contact">CONTACT</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script></script>

<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
.nav-link {
  font-size: 16px;
}
.dropdown-menu {
  border: none;
  background-color: inherit;
}
.dropdown-menu div {
  margin-bottom: 10px;
  margin-left: 15px;
}
.dropdown-item {
  font-size: 14px;
}
.dropdown-item {
  outline: none;
}
.dropdown-menu a:focus {
  background-color: inherit;
}
.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0);
}
</style>
