<template>
  <div class="index mx-3" style="min-width: 170px">
    <div class="index-header">
      <div class="d-none d-md-block my-4">
        <router-link class="h4" to="/">EmiIwado</router-link>
        <div class="small mt-1" style="color: gray">
          artist, designer, entrepreneur
        </div>
      </div>
      <router-link to="/" exact>WORK</router-link>
      <div class="index-content">
        <div>
          <router-link to="/work/happyvirus">Happy Virus</router-link>
        </div>
        <div>
          <router-link to="/work/executive">Executive Reproduction</router-link>
        </div>
        <div>
          <router-link to="/work/broken">Broken Heart</router-link>
        </div>
        <div>
          <router-link to="/work/ofuro">入学式 in OFURO</router-link>
        </div>
        <div>
          <router-link to="/work/dystopia">Dystopia-Utopia</router-link>
        </div>
        <div>
          <router-link to="/work/todai">Todai 女子のうた</router-link>
        </div>
      </div>
    </div>
    <div class="index-header">
      <router-link to="/about" class="index-header">ABOUT</router-link>
    </div>
    <div class="index-header">
      <router-link to="/contact">CONTACT</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
};
</script>

<style scoped>
.index-header {
  margin-bottom: 20px;
}
.index-content {
  margin: 0px 10px;
}
.index-content div {
  font-size: 14px;
  margin: 10px 0px;
}
a {
  text-decoration: none;
  color: inherit;
}
</style>
